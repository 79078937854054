.project{
    /* min-height: 100vh; */
    background-image: url('../../images/background-ui.png');
    
}
.project__container{
    width:80%;
    height:100%;
    margin: 0 auto;
    padding:80px 0px;
}
.project__container h1{
    font-size:56px;
    text-align: center;
    margin-top: 15px;
}

.project-heading p {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3f6b8b;
    letter-spacing: 1px;
  }
.tabs{
    display:flex;
    justify-content: center;
    align-items: center;
}

.tabsContainer{
    display:flex;
    flex-direction: row;
    border-radius: 8px;
    /* border: 2px solid #D8D8D8 */
    background-color: #FFFFFF;
    margin-top: 20px;
}
.button{
    padding: 8px 16px ;
    border-radius: 8px;
}
.button .button-text{
    font-size:16px
}

@media only screen and (max-width: 800px){
    .project__container h1{
        font-size:40px;

    }
    .project-heading p {
        text-align: center;
        font-size: 20px;
      }
}
@media only screen and (min-width: 800px) and (max-width: 1280px){
    .project__container{
        width: 90%;
    }
}