.card {
  align-self: stretch;
  width: calc(28% - 60px);
  border-radius: 8px;
  padding: 85px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0 5px rgb(1 41 112 / 8%);
  border-bottom: 3px solid var(--secondary);
}

.card h1 {
  font-size: 24px;
  font-weight: 500;
  padding: 10px 0px;
  color: #006494;
  margin: 0 !important;
}
.card p {
  text-align: center;
  padding: 0px;
  line-height: 25px;
  font-size: 16px;
  font-weight: 400;
  text-transform: none !important;
  color: rgb(14, 14, 14);
}
.card img {
  transform: scale(0.8);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 800px) {
  .card {
    height: 320px;
    width: 100%;
    margin: 15px 0px;
    padding: 30px 20px;
    box-sizing: border-box;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.35);
  }
  .card h1 {
    font-size: 24px;
    padding-bottom:10px;
    color: #006494;
  }
  .card p {
    overflow-y: hidden;
  }
  .card img{
    transform: scale(0.6)
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1280px) {
  .card {
    align-self: stretch;
    width: 28%;
    padding: 20px;
  }

  .card h1 {
    padding: 10px 0px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
  .card p {
    font-size: 14px;
    line-height: 20px;
  }
  .card h1 {
    font-size: 24px;
  }
  .card img {
    height: 60px;
    width: 60px;
  }
}
