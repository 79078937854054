.team{
    min-height: 100vh;
    background-image: url('../../images/background-ui.png');
}
.team__container{
    width:80%;
    margin: 0 auto;
    padding:80px 0px;
}
.team-heading p {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    color: #3f6b8b;
    letter-spacing: 1px;
  }
.team__container h1{
    font-size:56px;
    text-align: center;
    margin-top: 15px;
}

@media only screen and (max-width: 800px){
    .team__container h1{
        font-size:48px;
    }
    .team-heading p{
        font-size:20px
    }
}

@media only screen and (min-width: 800px) and (max-width: 1280px){
    .team__container{
        width:90%;
    }
}