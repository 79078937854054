.social__media {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}
.social__media img {
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 800px){
  .social__media{
    margin-top:15px;
  }
}