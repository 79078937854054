.services {
  min-height: 100vh;
  width: 100%;
  /* background-image: url("../../images/blueDesign.png"); */
  background-color: rgba(0,166, 251, 0.25);
  background-size: auto;
  background-repeat: repeat;
  /* box-shadow: inset 0px 0px 12px 0px rgb(0 0 0 / 10%); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.services__container {
  width: 80%;
  margin: 0 auto;
  height: 100%;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.services__container .service-heading p {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #3f6b8b;
  letter-spacing: 1px;
 
}
.services__container h1 {
  text-align: center;
  font-size: 52px;
  margin-top: 15px;
  margin-bottom: 40px;
}
.services__container .services__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60%;
  margin: 20px 0px;
}
.tech__stack {
  background-color: white !important;
  border-radius: 5px;
  height: 130px;
  position: relative;
  margin: 0px auto;
  width: 55.55vw;
  padding: 20px;
  display: none;
}
.tech__stack h1 {
  font-size: 32px;
  text-align: center;
}
.tech__stack .tech__lang {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -15px auto 0px auto;
  height: 110px;
  width: 90%;
  padding: 5px 0px 15px 0px;
}
.marquee {
  overflow: hidden;
  position: relative;
}
.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 3rem;
  height: 100%;
  z-index: 1;
  content: "";
}
.marquee:before {
  left: 0;
  background: linear-gradient(
    to right,
    rgb(255, 255, 255) 0%,
    transparent 100%
  );
}
.marquee:after {
  right: 0;
  background: linear-gradient(to left, rgb(255, 255, 255) 0%, transparent 100%);
}
.marquee__content {
  animation: scrolling 10s linear infinite;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}

@media only screen and (max-width: 800px) {
  .services__container{
    margin: 50px 0px;
  }
  .services__container .services__card {
    flex-direction: column;
    margin: 10px 0px;
    transform: none;
    position: relative;
  }
  .services {
    height: auto;
    background-image: url("../../images/background-ui.png");
    background-position: center;
    background-size: auto;
    background-repeat: repeat;
    padding-bottom: 20px;
  }
  .services__container h1 {
    font-size: 40px;
  }
  .service-heading p{
    font-size:20px
  }
  .services__card {
    margin-top: 10px;
  }
  .tech__stack {
    margin: 0px auto 0px auto;
    width: 80vw;
    position: relative;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.35);
    overflow-y: hidden;
  }
  .tech__stack h1 {
    font-size: 24px;
    text-align: center;
  }
  .tech__stack .tech__lang {
    margin: -12px auto 0px auto;
    padding: 5px 0px 15px 0px;
    width: 100%;
  }
  .marquee:before,
  .marquee:after {
    position: absolute;
    top: 0;
    width: 4rem;
    height: 100%;
    z-index: 1;
    content: "";
  }
  .marquee:before {
    left: -5px;
    background: linear-gradient(
      to right,
      rgb(255, 255, 255) 0%,
      transparent 100%
    );
  }
  .marquee:after {
    right: -5px;
    background: linear-gradient(
      to left,
      rgb(255, 255, 255) 0%,
      transparent 100%
    );
  }
  .marquee__content {
    animation: scrolling 10s linear infinite;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 110px;
    flex-shrink: 0;
  }
  @keyframes scrolling {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-229.28vw);
    }
  }
  .marquee__content .tech {
    width: 28.66vw;
    flex-shrink: 0;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .services__container h1 {
    font-size: 44px;
  }
  .services__container {
    width: 90%;
  }
  .services__container .services__card {
    margin: 40px 0px;
  }
}
