.navbar__container {
  height: 15vh;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 40px 0px;
  max-width: 80%;
  margin: 0 auto;
}
.navbar .toggle {
  display: none;
}
.navbar img {
  cursor: pointer;
}
.navbar .navbar__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  font-size: 24px;
  color: rgb(14, 14, 14);
}
.navbar .navbar__items .navlinks:hover {
  color: black;
}
.navbar__contact {
  border-radius: 4px;
  padding: 8px 20px;
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  cursor: pointer;
  color: white;
  box-shadow: 0px 15px 30px -12px rgb(0 0 0 / 20%);
}
.navbar__contact:hover {
  background-color: rgb(4, 78, 131);
  border: 1px solid rgb(4, 78, 131);
}
.navbar__contact a {
  color: white !important;
}
.navbar__contact a:hover {
  color: white !important;
}
.navbar .navbar__items a {
  text-decoration: none;
  color: rgb(14, 14, 14);
  cursor: pointer;
  font-weight: 700;
  font-size: 20px;
}
.navbar .navbar__items .navlinks:hover {
  color: rgb(4, 78, 131) !important;
}

@media only screen and (max-width: 800px) {
  .navbar__items {
    display: none !important;
  }
  .navbar img {
    width: 185px;
    height: 55px;
  }
  .navbar .toggle {
    display: block;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1280px) {
  .navbar img {
    width: 205px;
    height: 60px;
  }
  .navbar {
    max-width: 90%;
  }
  .navbar .navbar__items {
    width: 60% !important;
  }
}
