.person {
  display: flex;
  box-shadow: 0 10px 29px 0 rgb(68 88 144 / 20%);
  width: 100%;
  margin: 50px 0px;
  border-radius: 16px;
}
.person__photo {
  flex: 1;
  border-radius: 16px 0px 0px 16px;
}
.person__photo img {
  height: 100%;
  width: 100%;
}
.person__description {
  flex: 4;
}
.person__description .person__header {
  background-color: #003554;
  padding: 15px;
  color: white;
  font-size: 32px;
  font-weight: bold;
  border-radius: 0px 16px 0px 0px;
}
.person__description .person__desc {
  padding: 15px;
  font-size: 24px;
  background-color: #80caff;
  color: rgb(15, 15, 15);
  font-weight: 400;
  line-height: 130%;
  border-radius: 0px 0px 16px 0px;
}
.person__social__media {
  width: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
.person__social__media img {
  border-radius: 50%;
}
.person__photo img {
  display: none;
}

@media only screen and (max-width: 800px) {
  .person {
    display: flex;
    flex-direction: column;
    box-shadow: 4px 8px 18px rgba(0, 0, 0, 0.35);
    width: 100%;
    margin: 50px 0px;
  }
  .person__description .person__header {
    font-size: 28px;
    text-align: center !important;
  }
  .person__description .person__desc {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    font-weight: 500;
    text-align: center !important;
  }
  .person__social__media {
    justify-content: space-around !important;
    width: 50% !important;
  }
  .person__photo img {
    display: block;
    visibility: hidden;
    height: 50%;
  }
  .person__photo {
    order: -1;
  }
}
