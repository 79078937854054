.loader{
  height:100vh;
  width:100vw;
  background-color: white;
}
.spinner{

    width: 50px;
    height: 40px;
    text-align: center;
    position:sticky;
    top: calc(50% - 20px);
    left: calc(50% - 25px);
    margin: -15% 0 0 -25%;
    animation: fadein 2s;
  }
  
.pulse {
    display: inline-block;
    background: var(--primary);
    margin: 2px;
    height: 40px;
    width: 6px;
    animation: pulse 1.2s infinite;
  }
.rect2 {
    animation-delay: -1.1s !important;
}
.rect3 {
    animation-delay: -1.0s !important;
}
.rect4 {
    animation-delay: -0.9s !important;
}
.rect5 {
    animation-delay: -0.8s !important;
}

@keyframes pulse {
    0%, 40%, 100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
      background: var(--secondary);
    }
};

@keyframes fadein {
  from { opacity: 1; }
  to   { opacity: 0; }
}