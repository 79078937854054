.tech {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex-shrink: 0;
  width: 12.5vw;
}
.tech img {
  transform: scale(0.5);
}
.tech p {
}
