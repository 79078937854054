#sidebar {
  height: 0;
  width: 100vw;
  overflow: hidden;
  background-color: #FAFAFA;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  z-index: 99999;
  transition: 0.3s all ease-in-out;
}
.toggle-cross {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 7.5%;
}
.toggle-items {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position:absolute;
  top: 50%;
  transform: translateY(-50%);
  width:100%
}
.toggle__items {
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60% !important;

}
.toggle__items a {
  padding: 15px 0px;
  font-size: 28px;
  font-weight: 700;
  color: rgb(23, 23, 23);
}

.toggle__items .toggle__contact {
  border-radius: 4px;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  background-color: var(--primary);
  cursor: pointer;
  color: white;
  box-shadow: 0px 15px 30px -12px rgb(0 0 0 / 20%);
  margin:15px 0px;
}
